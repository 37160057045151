const instituteId = localStorage.getItem('instituteId') != null ? localStorage.getItem('instituteId') : ''
let inst = instituteId
let prd = instituteId
let campaign = instituteId
let stockurl = ''
if(instituteId != ''){
    let store = JSON.parse(localStorage.getItem(instituteId + 'commStore'))
    stockurl = store.pk
    inst = store.pk
    if(store.hasOwnProperty('product')){
        if(store.product == 1){
            prd = inst
        }
    }
    if(store.hasOwnProperty('campaign')){
        if(store.campaign == 1){
            campaign = inst
        }
    }
}
const myUrl = require("@/url")
const baseUrl = myUrl.url
const url          = baseUrl + '/commerce-service/'

module.exports = {
    settingGet                                  : url + 'setting/' + instituteId + '/get',
    settingCreate                               : url + 'setting/' + instituteId + '/create',
    campaignCreate                              : url + 'campaign/' + campaign + '/create',
    campaignGet                                 : url + 'campaign/' + campaign + '/get',
    campaignGets                                : url + 'campaign/' + campaign + '/list',
    campaignDelete                              : url + 'campaign/' + campaign + '/del',
    campCouponGets                              : url + 'campaign/coupon/' + campaign + '/get',
    itemSearch                                  : url + 'item/' + inst + '/search/',
    customerSearch                              : url + 'customer/' + instituteId + '/search/',
    rewardCreate                                : url + 'reward/' + instituteId + '/create',
    rewardGet                                   : url + 'reward/' + instituteId + '/get',
    rewardGets                                  : url + 'reward/' + instituteId + '/list',
    rewardDelete                                : url + 'reward/' + instituteId + '/del',
    partnerCreate                               : url + 'partner/' + campaign + '/create',
    partnerGet                                  : url + 'partner/' + campaign + '/get',
    partnerGets                                 : url + 'partner/' + campaign + '/list',
    deletePartner                               : url + 'partner/' + campaign + '/del',
    getAllItem                                  : url + 'allitem/' + inst + '/list',
    getPLItem                                   : url + 'allpricegets/' + inst + '/list',
    storeCreate                                 : url + 'store/' + instituteId + '/create',
    storeGet                                    : url + 'store/' + instituteId + '/get',
    storeGets                                   : url + 'store/' + instituteId + '/list',
    registerGets                                : url + 'register/' + inst + '/list',
    registerCreate                              : url + 'register/' + inst + '/create',
    pinCreate                                   : url + 'pin/' + instituteId + '/create',
    pinGet                                      : url + 'pin/' + instituteId + '/get',
    pinGets                                     : url + 'pin/' + instituteId + '/list',
    sesNumGenerate                              : url + 'session/' + inst + '/number',
    sessionCreate                               : url + 'session/' + inst + '/create',
    sessionGet                                  : url + 'session/' + inst + '/get',
    sessionGets                                 : url + 'session/' + inst + '/list',
    orderNumberGet                              : url + 'ordernumber/' + inst + '/get',
    txnCreate                                   : url + 'txn/' + inst + '/create',
    txnDevCreate                                : url + 'txndev/' + inst + '/create',
    txnGet                                      : url + 'txn/' + inst + '/get',
    txnGets                                     : url + 'txn/' + inst + '/list',
    txnDel                                      : url + 'txn/' + inst + '/del',
    txnNumberGet                                : url + 'txnnumber/' + inst + '/get',
    getGsi2                                     : url + 'generalgsit/' + inst + '/list',
    genGet                                      : url + 'general/' + inst + '/list',
    generalGet                                  : url + 'general/' + inst + '/get',
    reportGets                                  : url + 'report/' + inst + '/list',
    updateDb                                    : url + 'updatedb/add',
    cashCountCreate                             : url + 'cashcount/' + inst + '/add',
    frontDisplay                                : url + 'frontdisplay/' + inst + '/add',
    keyGet                                      : url + 'key/' + instituteId + '/get',
    updateServing                               : url + 'serving/' + inst + '/update',
    checkDel                                    : url + 'checkcode/' + inst + '/del',
    signInKHQR                                  : url + 'khqr/' + inst + '/signin',
    storeLogin                                  : url + 'store/login',
    firebasePost                                : url + 'firebase/' + inst + '/add',
    generalGets                                 : url + 'general/' + inst + '/list',
    getItemByCate                               : url + 'itemcategory/' + prd + '/list',
    stockPost                                   : url + 'addstock/' + stockurl + '/create',
    generalPost                                 : url + 'generalpost/add',
}
