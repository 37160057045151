// const session = localStorage.getItem('banhjipossession') != null ? JSON.parse(localStorage.getItem('banhjipossession')) : {}
// const inst = session.ins || {}
const instituteId = localStorage.getItem('instituteId') != null ? localStorage.getItem('instituteId') : ''
const myUrl = require("@/url")
const baseUrl = myUrl.url
const url          = baseUrl + '/entity-member/'
// const url          = 'https://dev-apis.banhji.com/entity-members/'

module.exports = {
    member_post                                 : url + 'member/institute/' + instituteId + '/add',
    member_get                                  : url + 'member/institute/' + instituteId + '/list',
    member_getOne                               : url + 'member/institute/' + instituteId + '/list/',
    member_search                               : url + 'member/institute/' + instituteId + '/search',
    member_center_get                           : url + 'member/institute/' + instituteId + '/center/',
    saving_account_post                         : url + 'savingaccount/institute/' + instituteId + '/add',
    saving_account_get                          : url + 'savingaccount/institute/' + instituteId + '/list',
    saving_account_center_get                   : url + 'savingaccount/institute/' + instituteId + '/centerlist',
    saving_account_getOne                       : url + 'savingaccount/institute/' + instituteId + '/list/',
    saving_account_getByMember                  : url + 'savingaccount/institute/' + instituteId + '/member/',
    saving_deposit_post                         : url + 'savingdeposit/institute/' + instituteId + '/add',
    saving_deposit_getByAccount                 : url + 'savingdeposit/institute/' + instituteId + '/account/',
    saving_interest_getByAccount                : url + 'savinginterest/institute/' + instituteId + '/account/',
    saving_balance_getByAccount                 : url + 'savingbalance/institute/' + instituteId + '/account/',
    saving_withdraw_post                        : url + 'savingwithdraw/institute/' + instituteId + '/add',
    saving_monthof_post                         : url + 'savingclosemonth/institute/' + instituteId + '/search',
    close_saving_post                           : url + 'savingaccount/institute/' + instituteId + '/close',
    //loan
    loan_post                                   : url + 'loan/institute/' + instituteId + '/add',
    loan_getOne                                 : url + 'loan/institute/' + instituteId + '/list/',
    receipt_amount_post                         : url + 'receiptamount/institute/' + instituteId + '/add',
    receipt_loan_post                           : url + 'receiptloan/institute/' + instituteId + '/add',
    loan_list_report_post                       : url + 'reploanlist/institute/' + instituteId + '/add',
    loan_disburse_report_post                   : url + 'loandisburse/institute/' + instituteId + '/add',
    loan_next_report_post                       : url + 'loannextmonth/institute/' + instituteId + '/add',
    loan_approv_get                             : url + 'loanapprov/institute/' + instituteId + '/list',
    lastnumber                                  : url + 'lastnumber/institute/' + instituteId + '/add',
    loan_getByMember                            : url + 'loan/institute/' + instituteId + '/member/',
    disbursement_post                           : url + 'disbursement/institute/' + instituteId + '/add',
    loan_center_get                             : url + 'loan/institute/' + instituteId + '/centerlist',
    share_post                                  : url + 'membershare/institute/' + instituteId + '/add',
    loan_schedule_getByLoan                     : url + 'loanschedule/institute/' + instituteId + '/list/',
    saving_list_report_post                     : url + 'repsavinglist/institute/' + instituteId + '/add',
    saving_to_close_report_post                 : url + 'repsavingtoclose/institute/' + instituteId + '/add',
    saving_closemonth_post                      : url + 'savingclosemonth/institute/' + instituteId + '/add',
    center_txn_search                           : url + 'center/institute/' + instituteId + '/searchtxn',
    shareGetByMember                            : url + 'membershare/institute/' + instituteId + '/searchid',
    shareWithdraw                               : url + 'sharewithdraw/institute/' + instituteId + '/add',
    listShareReport                             : url + 'sharereport/institute/' + instituteId + '/list',
    loanPortfolioReport                         : url + 'loanportfolio/institute/' + instituteId + '/report',
    closeLoanPost                               : url + 'closeloan/institute/' + instituteId + '/add',
    savingDepositReport                         : url + 'savingdepositreport/institute/' + instituteId + '/list',
    savingStatisticSummaryReport                : url + 'savingstatisticsummary/institute/' + instituteId + '/report',
    loanStatisticSummaryReport                  : url + 'loanstatisticsummary/institute/' + instituteId + '/report',
    loadLessMember                              : url + 'member/institute/' + instituteId + '/less',
    loadLessCustomer                            : url + 'customer/institute/' + instituteId + '/less',
    creditBizSummaryReport                      : url + 'creditsummaryreport/institute/' + instituteId + '/report/',
    importMember                                : url + 'memberimport/institute/' + instituteId + '/add',
    memberLessSearch                            : url + 'lessmember/institute/' + instituteId + '/search/',
    customerLessSearch                          : url + 'lesscustomer/institute/' + instituteId + '/searchbyname/',
    memberCountGet                              : url + 'member/institute/' + instituteId + '/count',
    memberListReport                            : url + 'memberreport/institute/' + instituteId + '/list/',
    shareByProductGet                           : url + 'sharebyproduct/institute/' + instituteId + '/get/',
    calculateAVGShare                           : url + 'calavgshare/institute/' + instituteId + '/get',
    createDividend                              : url + 'dividend/institute/' + instituteId + '/create',
    dividendGets                                : url + 'dividend/institute/' + instituteId + '/list',
    memberDividendGet                           : url + 'memberdividend/institute/' + instituteId + '/get/',
    dividendPaymentCreate                       : url + 'dividendpayment/institute/' + instituteId + '/add',
    loanRescheduleCreate                        : url + 'loanreschedule/institute/' + instituteId + '/add',
}