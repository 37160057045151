/* Cookie */
// const session = localStorage.getItem('banhjipossession') != null ? JSON.parse(localStorage.getItem('banhjipossession')) : {}
// const inst = session.ins || {}
const instituteId = localStorage.getItem('instituteId') != null ? localStorage.getItem('instituteId') : ''

const myUrl = require("@/url")
let baseUrl = myUrl.url
// if(process.env.VUE_APP_MODE == 'dev'){
//     baseUrl = 'https://dev-apis.banhji.com'
// }
const url          = baseUrl + '/partner-service/v1/'

module.exports = {
    bank_connected_get                                : url + 'institutes/' + instituteId + '/banks',
}